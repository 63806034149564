import { useStaticQuery, graphql } from "gatsby"

export const useHomePage = () => {
  const { page } = useStaticQuery(
    graphql`
      query HomePageQuery {
        page: contentfulPage(
          slug: { eq: "home" }
          node_locale: { eq: "en-US" }
        ) {
          body {
            raw
          }
          footer {
            raw
          }
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              resizingBehavior: SCALE
              placeholder: TRACED_SVG
              width: 650
              formats: AUTO
            )
            title
            updatedAt
          }
          title
          slug
          keywords
        }
      }
    `
  )

  return { homePage: page }
}
