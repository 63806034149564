import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import AttorneysSection from "../components/attorneys-section";
import ContactSection from "../components/contact-section";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useHomePage } from "../hooks/use-home-page";
import { usePracticeCategories } from "../hooks/use-practice-categories";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default function RootIndex() {
  //const { attorneys } = useAttorneys();
  const { homePage } = useHomePage();
  const { practiceCategories } = usePracticeCategories();

  return (
    <Layout>
      <Seo
        title="Martin, Cukjati &amp; Tom, LLP"
        keywords={`business lawyer,business attorneys,contract,injury`}
        description={`Experienced business attorneys and injury lawyers representing businesses and client throughout Texas.`}
      />
      <section class="hero has-background-white ">
        <div class="columns hero-body">
          <div class="column front-hero-left-column mr-large-4">
            <div class="p-0">
              <h1 class="title is-size-3 has-text-weight-semibold">
                <span className="has-text-primary">
                  Experienced business and injury lawyers
                </span>{" "}
                finding creative ways to help those who really need it.
              </h1>
              <p class="is-size-6 mb-4 font-front">
                Martin Cukjati &amp; Tom, LLP is a full service law firm with
                over 75 years of combined legal experience representing people
                and businesses in high-stakes litigation. The cornerstone of our
                success is limiting our case load and dedicating ourselves to
                serving a select few clients, making sure your case receives the
                attention it deserves. This allows us to focus on our clients,
                and work towards achieving the best possible outcome.
              </p>
              <div class="buttons">
                <Link to="/contact/">
                  <button class="button is-primary  mr-3">Contact us</button>
                </Link>
                <Link to="/about/">
                  <button class="button">About us</button>
                </Link>
              </div>
            </div>
          </div>
          <div class="column is-hidden-mobile ">
            <GatsbyImage
              className="home-hero-image"
              placeholder="traced_svg"
              width={homePage.image.gatsbyImageData.width}
              height={homePage.image.gatsbyImageData.height}
              alt={homePage.image.title}
              image={getImage(homePage.image)}
            />
          </div>
        </div>
      </section>
      <section class="hero is-primary hero-border-1">
        <div class="hero-body">
          <p class="title mb-6">Our Values</p>
          <p class="subtitle is-size-5">
            As a boutique law firm in San Antonio, Texas, our lawyers understand
            the value of building a relationship with our clients. We take the
            time to listen and determine what your goals are. With an attention
            to detail for every nuance of a case, our attorneys are prepared to
            aggressively reach the best resolution possible. At Martin Cukjati
            &amp; Tom, LLP, we also fundamentally believe in treating our staff,
            the courtroom staff, and opposing counsel with the utmost respect.
            These values have guided our practice for over three decades.
          </p>
        </div>
      </section>
      <section>
        <AttorneysSection large>
          <h2 className="title">Meet Our Lawyers</h2>
          <p className="subtitle mb-6">
            We are battle tested trial lawyers and dedicated to serving our
            clients.
          </p>
        </AttorneysSection>
      </section>

      <section class="hero has-background-primary hero-border-1">
        <div class="hero-body">
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-4">
              <div className="tile is-child px-3">
                <p class="title mb-6 has-text-white-ter">Practice Areas</p>
                <p class="subtitle is-size-6 has-text-white-ter">
                  Our attention to detail and dedication to finding the right
                  solutions for our clients are the foundation of our practice.
                  Find out how we can work with you to resolve your legal
                  issues.
                </p>
                <Link to="/practice-areas/" className="has-text-white-ter">
                  View all practice areas &gt;
                </Link>
              </div>
            </div>
            <div class="tile is-child">
              <div class="columns is-multiline">
                {practiceCategories.map(({ node }) => (
                  <div className="column is-half">
                    <div className="p-3 box has-background-white-bis hero-border-top">
                      <div className="is-size-7 has-text-weight-semibold">
                        Practice Areas
                      </div>
                      <h2 className="title is-size-4">
                        <Link to={`/practice-areas/${node.slug}/`}>
                          {node.title}
                        </Link>
                      </h2>
                      <div className="content">
                        {renderRichText(node.shortBody)}
                      </div>
                      <Link to={`/practice-areas/${node.slug}/`}>
                        <button class="button">About {node.title} &gt;</button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="has-background-white">
        <ContactSection>
          <h4 class="title is-spaced is-4">Let's talk about your case</h4>
          <p class="subtitle">
            We're here to answer your questions and we can work with you to
            resolve your legal issues.
          </p>
        </ContactSection>
      </section>
    </Layout>
  );
}
